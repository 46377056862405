module.exports={
  "name": "silver-decisions",
  "version": "1.2.1",
  "description": "Software for creating and analyzing decision trees.",
  "main": "index.js",
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/SilverDecisions/SilverDecisions.git"
  },
  "author": "Michał Wasiluk, Bogumił Kamiński, Przemysław Szufel",
  "license": "LGPL-3.0",
  "bugs": {
    "url": "https://github.com/SilverDecisions/SilverDecisions/issues"
  },
  "homepage": "https://github.com/SilverDecisions/SilverDecisions#readme",
  "browserslist": [
    "last 3 versions",
    "> 0.25%",
    "Firefox ESR",
    "not dead"
  ],
  "browserify": {
    "transform": [
      [
        "babelify",
        {
          "global": true,
          "ignore": [
            "/\\/node_modules\\/(?!d3-.+\\/)/"
          ],
          "presets": [
            "@babel/preset-env"
          ],
          "plugins": [
            [
              "transform-class-properties",
              {
                "spec": true
              }
            ],
            "transform-object-assign",
            [
              "babel-plugin-transform-builtin-extend",
              {
                "globals": [
                  "Error"
                ]
              }
            ],
            [
              "@babel/plugin-transform-runtime",
              {
                "regenerator": true,
                "helpers": false
              }
            ]
          ]
        }
      ]
    ]
  },
  "devDependencies": {
    "@babel/core": "^7.15.8",
    "@babel/plugin-proposal-object-rest-spread": "^7.15.6",
    "@babel/plugin-transform-runtime": "^7.15.8",
    "@babel/preset-env": "^7.15.8",
    "babel-plugin-transform-builtin-extend": "^1.1.2",
    "babel-plugin-transform-class-properties": "^6.24.1",
    "babel-plugin-transform-es2015-spread": "^6.22.0",
    "babel-plugin-transform-object-assign": "^6.22.0",
    "babelify": "^10.0.0",
    "browser-sync": "^2.27.5",
    "browserify": "^17.0.0",
    "browserify-shim": "^3.8.14",
    "chalk": "^4.1.2",
    "del": "^6.0.0",
    "es6-set": "^0.1.5",
    "gulp": "^4.0.2",
    "gulp-clean-css": "^4.3.0",
    "gulp-concat": "^2.6.1",
    "gulp-filelist": "^2.0.5",
    "gulp-html2js": "^0.4.2",
    "gulp-load-plugins": "^2.0.7",
    "gulp-plumber": "^1.2.1",
    "gulp-rename": "^2.0.0",
    "gulp-replace": "^1.1.3",
    "gulp-sass": "^5.0.0",
    "gulp-sourcemaps": "^3.0.0",
    "gulp-strip-debug": "^4.0.0",
    "gulp-template": "^5.0.0",
    "gulp-uglify": "^3.0.2",
    "gulp-util": "^3.0.8",
    "jasmine": "^3.10.0",
    "jasmine-jquery": "^2.1.1",
    "karma": "^6.3.4",
    "karma-browserify": "^8.1.0",
    "karma-chrome-launcher": "^3.1.0",
    "karma-intl-shim": "^1.0.3",
    "karma-jasmine": "^4.0.1",
    "merge-stream": "^2.0.0",
    "odc-d3": "^2.0.0",
    "run-sequence": "^2.2.1",
    "sass": "^1.43.2",
    "stringify": "^5.2.0",
    "svg2pdf.js": "^2.2.0",
    "vinyl-buffer": "^1.0.1",
    "vinyl-source-stream": "^2.0.0",
    "yargs": "^17.2.1"
  },
  "dependencies": {
    "autosize": "^5.0.1",
    "blueimp-canvas-to-blob": "^3.29.0",
    "d3-array": "^3.1.1",
    "d3-brush": "^3.0.0",
    "d3-dispatch": "^3.0.1",
    "d3-drag": "^3.0.0",
    "d3-scale": "^4.0.2",
    "d3-scale-chromatic": "^3.0.0",
    "d3-selection": "^3.0.0",
    "d3-shape": "^3.0.1",
    "d3-time-format": "^4.0.0",
    "file-saver": "^2.0.5",
    "hammerjs": "^2.0.8",
    "i18next": "^21.3.2",
    "jquery": "^3.6.0",
    "jquery-ui": "^1.13.0",
    "pivottable": "^2.23.0",
    "sd-computations": "^0.6.1",
    "sd-model": "~0.2.11",
    "sd-tree-designer": "^0.3.1",
    "sd-utils": "~0.1.15"
  }
}
